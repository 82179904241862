.content .artist_section div.artist_box a[href]:link, .content .artist_section div.artist_box a[href]:visited, .content .artist_section div.artist_box a[href]:hover, .content .artist_section div.artist_box a[href]:active, .content .artist_section div.artist_box a[href]:focus {
  white-space: normal;
  border-bottom: 1px #0000;
}

.artist_section .grid {
  text-align: center;
}

.artist_section {
  xmargin-bottom: 3em;
  xborder-bottom: 1px solid #444;
  padding-bottom: 1em;
}

.artist_section h3 {
  text-transform: uppercase;
  xborder-bottom: 1px solid #444;
  color: #37bf7b;
  border-top: 1px solid #444;
  margin: 0 0 1.5em;
  padding: 1.5em 0 0;
  font: 35px / 35px Source Sans Pro, sans-serif;
}

.artist_box {
  vertical-align: middle;
  xmargin: 0 10px 8px 0;
  xoverflow: hidden;
  xfloat: left;
  xbackground-color: #0b4d1680;
  background-size: cover;
  width: 340px;
  min-height: 120px;
  margin: 0 10px 16px 0;
  display: inline-block;
  position: relative;
}

.artist_box.withcover {
  vertical-align: top;
}

.artist_cover {
  border-radius: 50%;
  width: 290px;
  height: 290px;
  margin: 8px;
}

.nocover .artist_wrap {
  o-o-transform: translate(0, -50%);
  position: relative;
  top: 40px;
  transform: translate(0, -50%);
}

.section_saviugda .nocover .artist_wrap {
  top: 62px;
}

.artist_box.daugiau a {
  z-index: 10;
  text-transform: uppercase;
  xtext-align: center;
  width: 390px;
  height: 190px;
  padding: 0;
  font: 60px / 60px Source Sans Pro, sans-serif;
  display: block;
}

x.artist_box.headliner {
  -khtml-border-radius: 3px;
  border-radius: 3px;
  width: 390px;
  min-height: 290px;
  margin-bottom: 16px;
  margin-right: 10px;
}

.artist_box .artist_hover {
  display: none;
}

.artist_box a.artist_hover {
  display: block;
}

.artist_box a.artist_hover:hover, .artist_box a.artist_hover:active, .artist_box a.artist_hover:focus {
  xbackground: #fcfcfc14;
  border: none;
  text-decoration: none;
}

.artist_box.withphoto:active .artist_hover, x.artist_box.headliner:active .artist_hover, .artist_box.withphoto:focus .artist_hover, x.artist_box.headliner:focus .artist_hover, .artist_box.withphoto:hover .artist_hover, x.artist_box.headliner:hover .artist_hover {
  xbackground: #fff3;
  background: #00000080;
}

.artist_bio_lt, .artist_bio_en {
  display: none;
}

.artist_foto {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.artist_foto img {
  -khtml-border-radius: 3px;
  border-radius: 3px;
}

span.live {
  xbackground-color: #c73f3f;
  xbackground-color: #eb0074;
  xbackground-color: #c06;
  color: #fff;
  -khtml-border-radius: 2px;
  background: #e82e54;
  border-radius: 2px;
  padding: 1px 3px;
  font-size: .8em;
}

div.artist_box h4 span {
  xxdisplay: inline;
  color: #fff;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  margin-left: 5px;
  padding: 0 3px;
  font-size: .8em;
  text-decoration: none;
  display: none;
  position: relative;
  top: -2px;
  overflow: visible;
}

.artist_box h4.artist_name {
  xxfont: normal 25px / 27px "Source Sans Pro", sans-serif;
  xxfont-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
}

div.artist_box h4.artist_name span.live, div.artist_box h4.artist_name span.sound_system {
  background-color: #bf377b;
  display: inline;
}

div.artist_box h4.artist_name span.elected, div.artist_box h4.artist_name span.showcase {
  background-color: #bf7b37;
  display: inline;
}

div.artist_box h4.artist_name span.new {
  background-color: #38ac7d;
  display: inline;
}

div.artist_box h4.artist_name span.deco, div.artist_box h4.artist_name span.fx, div.artist_box h4.artist_name span.vj {
  background-color: #377bbf;
  display: inline;
}

div.artist_box.cancel {
  opacity: .5;
}

div.artist_box.cancel .artist_meta, div.artist_box.cancel .artist_links, div.artist_box.cancel .artist_foto {
  display: none;
}

div.artist_box.cancel h4.artist_name {
  xcolor: #666;
  text-decoration: line-through;
}

div.artist_box h4.artist_name span.cancel {
  xdisplay: inline-block;
  background-color: #888;
  font-size: 12px;
  text-decoration: none;
}

.artist_country {
  text-align: center;
  color: #888;
  xmargin-right: 10px;
  xmargin-bottom: 4px;
  white-space: nowrap;
  xline-height: 1.5em;
  letter-spacing: 1px;
  border: 0;
  min-width: 12px;
  display: inline-block;
}

.artist_country:hover {
  color: #fff;
}

.artist_meta {
  display: static;
  text-transform: uppercase;
  xfont-size: 10.5px;
  xfont-size: .78125rem;
  margin-top: 2px;
  font-family: Source Sans Pro, Trebuchet MS, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.2em;
}

.artist_box.withphoto .artist_meta {
  -o-transition: all .1s ease-in-out;
  max-height: 0;
  transition: all .1s ease-in-out;
  overflow: hidden;
}

.artist_box.withphoto:hover .artist_meta {
  xbackground-color: red;
  max-height: 200px;
}

.artist_box.withphoto.cancel:hover .artist_meta, .artist_box.cancel:hover .artist_desc, .artist_box.cancel:focus .artist_desc, .artist_box.cancel:active .artist_desc {
  max-height: 0;
}

.artist_meta {
  margin-left: 10px;
  margin-right: 10px;
}

.artist_label {
  letter-spacing: 1px;
  color: #a8a8a8;
  xcolor: #ccc;
  xmargin-left: 10px;
  line-height: 1.5em;
}

.artist_label em {
  xtext-transform: none;
  font-size: 8px;
  font-style: normal;
}

x.headliner .artist_label {
  color: #bbb;
}

.detail_label {
  letter-spacing: 1px;
  xcolor: silver;
  color: #faf6be;
  text-transform: uppercase;
  margin-top: -1.5em;
  font-family: Source Sans Pro, Trebuchet MS, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: .8em;
  font-weight: 400;
  line-height: 1.5em;
}

.artist_meta span a[target="_blank"]:before, a.artist_link[target="_blank"]:before {
  content: "";
}

.artist_meta span a:link, .artist_meta span a:visited {
  color: #ccc;
  padding: 0;
}

.artist_meta span a:hover, .artist_meta span a:active {
  color: #fff;
  background: none;
  padding: 0;
}

.artist_links {
  font-size: 12px;
  line-height: 1em;
  display: inline-block;
}

.artist_desc {
  xpadding: 20px;
  xpadding: 0 10px;
  xbackground-color: black;
  xbackground-color: #000000f2;
  z-index: 10;
  xdisplay: none;
  -o-transition: all .1s ease-in-out;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  max-height: 0;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 190px;
  overflow: hidden;
}

.artist_desc > div {
  xpadding-left: 10px;
  xpadding-right: 10px;
  width: 170px;
  padding: 0;
  position: relative;
}

x.artist_box.headliner .artist_desc {
  top: 290px;
}

x.artist_box.headliner .artist_desc > div {
  width: 350px;
}

.artist_box .yt-button {
  cursor: pointer;
  xtop: 50%;
  z-index: 63;
  color: inherit;
  text-align: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  opacity: 0;
  background-color: #0000;
  width: 68px;
  height: 48px;
  margin-top: -24px;
  margin-left: -34px;
  padding: 0;
  transition: opacity .25s cubic-bezier(0, 0, .2, 1);
  position: absolute;
  top: 154px;
  left: 50%;
  border: none !important;
}

.yt-button svg {
  xheight: 100%;
  xleft: 0;
  xposition: absolute;
  xtop: 0;
  xwidth: 100%;
}

.ytp-large-play-button-bg {
  fill: #212121;
  fill-opacity: .8;
  transition: fill .1s cubic-bezier(.4, 0, 1, 1), fill-opacity .1s cubic-bezier(.4, 0, 1, 1);
}

.artist_box:hover .ytp-large-play-button-bg {
  xfill: red;
  xfill-opacity: 1;
  transition: fill .1s cubic-bezier(0, 0, .2, 1), fill-opacity .1s cubic-bezier(0, 0, .2, 1);
}

.content .artist_box:hover .yt-button {
  opacity: 1;
  border-bottom: 0;
}

.artist_box h4.artist_name em {
  font-size: .8em;
  font-style: normal;
  font-weight: 300;
}

.artist_box h4.artist_name em:before {
  content: "\a ";
  white-space: pre;
}

.artist_box.headliner h4.artist_name {
  margin-top: -8px;
  font: 300 33px / 35px Source Sans Pro, sans-serif;
}

h2.program_title {
  margin-top: .5em;
}

.accordion-toggle {
  display: none;
}

.accordion-title {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: all .1s;
  display: block;
}

.accordion-content {
  transition: all .1s;
  display: none;
}

.accordion-toggle:checked + .accordion-title + .accordion-content {
  display: block;
}

.accordion-title:active {
  color: #f48471;
}

.accordion-title h2:after {
  content: " +";
  white-space: nowrap;
}

.accordion-toggle:checked + .accordion-title h2:after {
  content: " -";
}
/*# sourceMappingURL=single.074cccbe.css.map */
