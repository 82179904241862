
.content .artist_section div.artist_box a[href]:link,
.content .artist_section div.artist_box a[href]:visited {    border-bottom: 1px transparent; white-space: normal; }
.content .artist_section div.artist_box a[href]:hover,
.content .artist_section div.artist_box a[href]:active,
.content .artist_section div.artist_box a[href]:focus   {    border-bottom: 1px transparent; white-space: normal; }

/*** ARTISTS / LINEUP ***/


.artist_section .grid { text-align: center; }

.artist_section {
    padding-bottom: 1em;
    xmargin-bottom: 3em;
    xborder-bottom: 1px solid #444;
}
.artist_section h3 {
    font: normal 35px/35px 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    padding: 1.5em 0 0 0;
    margin: 0em 0 1.5em 0;
    xborder-bottom: 1px solid #444;
    border-top: 1px solid #444;
    color: #37bf7b;
}
.artist_box {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 340px;
    min-height: 120px;
    xmargin: 0 10px 8px 0;
    margin: 0 10px 16px 0;
    xoverflow: hidden;
    xfloat: left;
    background-size: cover;
    xbackground-color: rgba( 11,77,22,.5);
}
.artist_box.withcover {
    vertical-align: top;
}
.artist_cover {
    margin: 8px;
    border-radius: 50%;
    width: 290px;
    height: 290px;

}

.nocover .artist_wrap {
    position: relative;
    top: 40px;
    -webkit-transform: translate(0,-50%);
    -moz-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    o-o-transform: translate(0,-50%);
    transform: translate(0,-50%);
}
.section_saviugda .nocover .artist_wrap {
    top: 62px; /* XXX kodel procentai neveikia */
}

.artist_box.daugiau a {
    z-index: 10;
    width: 390px;
    height: 190px;
    padding: 0;
    font: normal 60px/60px 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    xtext-align: center;
    display: block;
}

x.artist_box.headliner {
    width: 390px;
    margin-right: 10px;
    min-height: 290px;
    -moz-border-radius:     3px;
    -webkit-border-radius:  3px;
    -khtml-border-radius:   3px;
    border-radius:          3px;
    margin-bottom: 16px;
}
.artist_box .artist_hover {
    display: none;
}

.artist_box a.artist_hover {
    display: block;

}
.artist_box a.artist_hover:hover, .artist_box a.artist_hover:active, .artist_box a.artist_hover:focus {
    text-decoration: none;
    xbackground: rgba( 252,252,252,.08);
    border: none;
}

.artist_box.withphoto:active .artist_hover,
x.artist_box.headliner:active .artist_hover,
.artist_box.withphoto:focus .artist_hover,
x.artist_box.headliner:focus .artist_hover,
.artist_box.withphoto:hover .artist_hover,
x.artist_box.headliner:hover .artist_hover {
    xbackground: rgba(255,255,255,.2);
    background: rgba(0,0,0,.5);
}

.artist_bio_lt, .artist_bio_en {
    display: none;
}

.artist_foto {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
.artist_foto img {
    -moz-border-radius:     3px;
    -webkit-border-radius:  3px;
    -khtml-border-radius:   3px;
    border-radius:          3px;
}

span.live {
    xbackground-color: #C73F3F;
    xbackground-color: #eb0074;
    xbackground-color: #cc0066;
    background: #e82e54;
    color: white;
        font-size: 0.8em;
    padding:    1px 3px;
    -moz-border-radius:     2px;
    -webkit-border-radius:  2px;
    -khtml-border-radius:   2px;
    border-radius:          2px;
}

div.artist_box h4 span {
    display:none;
    xxdisplay: inline;
    position: relative;
    overflow: visible;
    top: -2px;
    color: white;
    font-size: 0.8em;
    padding:    0px 3px;
    -moz-border-radius:     3px;
    -webkit-border-radius:  3px;
    -khtml-border-radius:   3px;
    border-radius:          3px;
    margin-left: 5px;
    text-decoration: none;
}

.artist_box h4.artist_name {
    xxfont: normal 25px/27px 'Source Sans Pro', sans-serif;
    font-size: 24px;
    font-weight: bold;
    xxfont-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 0px 0 0 0px;
    margin: 0;
}

.artist_box h4.artist_name em {
    font-weight: 300;
    font-size: 0.8em;
    font-style: normal;
}

.artist_box h4.artist_name em::before{
    content: "\a";
    white-space: pre;
}

/*.artist_box.headliner h4.artist_name {
    font: 300 33px/35px 'Source Sans Pro', sans-serif;
    margin-top: -8px;
}*/



div.artist_box h4.artist_name span.live {
    display: inline;
    background-color: #bf377b;
}

div.artist_box h4.artist_name span.sound_system {
    display: inline;
    background-color: #bf377b;
}

div.artist_box h4.artist_name span.elected,
div.artist_box h4.artist_name span.showcase {
    display: inline;
    background-color: #bf7b37;
}

div.artist_box h4.artist_name span.new {
    display: inline;
    background-color: #38ac7d;
}

/*div.artist_box h4.artist_name span.paskaita,*/
div.artist_box h4.artist_name span.deco,
div.artist_box h4.artist_name span.fx,
div.artist_box h4.artist_name span.vj {
    display: inline;
    background-color: #377bbf;
}


div.artist_box.cancel {
    opacity: .5;
}
div.artist_box.cancel .artist_meta,
div.artist_box.cancel .artist_links,
div.artist_box.cancel .artist_foto {
    display: none;
}
div.artist_box.cancel h4.artist_name {
    text-decoration: line-through;
    xcolor: #666;
}
div.artist_box h4.artist_name span.cancel {
    xdisplay: inline-block;
    font-size: 12px;
    background-color: #888;
    text-decoration: none;

}


.artist_country{
    display: inline-block;
    text-align: center;
    min-width: 12px;
    color:   #888;
    xmargin-right: 10px;
    xmargin-bottom: 4px;
    white-space: nowrap;
    xline-height: 1.5em;
    letter-spacing: 1px;
    border: 0px;

}
.artist_country:hover {
    color: #fff;
}

.artist_meta {
    display: static;
    font-family: "Source Sans Pro", "Trebuchet MS", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    xfont-size:10.5px;
    xfont-size: 0.78125rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.2em;
    margin-top: 2px;
}
.artist_box.withphoto .artist_meta {
    max-height: 0px;
    overflow: hidden;
        -webkit-transition: all .1s ease-in-out;
        -moz-transition: all .1s ease-in-out;
        -o-transition: all .1s ease-in-out;
        -ms-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
}
.artist_box.withphoto:hover .artist_meta {
    xbackground-color: red;
    max-height: 200px;
}

.artist_box.withphoto.cancel:hover .artist_meta,
.artist_box.cancel:hover .artist_desc,
.artist_box.cancel:focus .artist_desc,
.artist_box.cancel:active .artist_desc
{
    max-height: 0px;
}

.artist_meta { margin-left: 10px; margin-right: 10px; }
.artist_label { letter-spacing:1px; line-height: 1.5em; color: #a8a8a8; xcolor: #ccc; xmargin-left: 10px; }
.artist_label em { font-style: normal; font-size: 8px; xtext-transform: none; }
x.headliner .artist_label { color: #bbb; }

.detail_label { letter-spacing:1px; line-height: 1.5em; xcolor: #c0c0c0; color: #faf6be;
    font-family: "Source Sans Pro", "Trebuchet MS", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.8em;
    text-transform: uppercase;
    margin-top: -1.5em;
}

.artist_meta span a[target=_blank]:before { content:''; }
a.artist_link[target=_blank]:before { content:''; }
.artist_meta span a:link, .artist_meta span a:visited { color: #ccc; padding: 0; }
/*
.headliner .artist_meta a:link, .headliner .artist_meta a:visited { color: #bbb; }
.headliner .artist_meta a:hover, .headliner .artist_meta a:active,
*/
.artist_meta span a:hover, .artist_meta span a:active { color: #fff; background: none; padding: 0;}

.artist_links {
    display: inline-block;
    font-size: 12px;
    line-height: 1em;
}


.artist_desc {
    position: absolute;
    display: block;
    xpadding: 20px;
    xpadding: 0 10px;
    xbackground-color: black;
    xbackground-color: rgba(0,0,0,.95);
    width: 100%;
    top: 190px;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    xdisplay: none;
        -webkit-transition: all .1s ease-in-out;
        -moz-transition: all .1s ease-in-out;
        -o-transition: all .1s ease-in-out;
        -ms-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
    -moz-border-radius:     3px;
    -webkit-border-radius:  3px;
    -khtml-border-radius:   3px;
    border-radius:          3px;
}

.artist_desc > div { position:relative; padding: 0; xpadding-left: 10px; xpadding-right: 10px; width: 170px; }
x.artist_box.headliner .artist_desc { top: 290px; }
x.artist_box.headliner .artist_desc > div { width: 350px; }

.artist_box .yt-button
{
    cursor: pointer;
    position: absolute;
    left: 50%;
    xtop: 50%;
    top: 154px;
    width: 68px;
    height: 48px;
    margin-left: -34px;
    margin-top: -24px;
    -moz-transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
    -webkit-transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
    transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
    z-index: 63;
    border: none !important;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-align: inherit;
    font-size: 100%;
    font-family: inherit;
    line-height: inherit;
    opacity: 0;
}

.yt-button svg {
    xheight: 100%;
    xleft: 0;
    xposition: absolute;
    xtop: 0;
    xwidth: 100%;
}
.ytp-large-play-button-bg {
    -moz-transition: fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1);
    -webkit-transition: fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1);
    transition: fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1);
    fill: #212121;
    fill-opacity: .8;
}

/*.ytp-large-play-button.ytp-touch-device .ytp-large-play-button-bg, .ytp-cued-thumbnail-overlay:hover .ytp-large-play-button-bg */
.artist_box:hover .ytp-large-play-button-bg
{
    -moz-transition: fill .1s cubic-bezier(0.0,0.0,0.2,1),fill-opacity .1s cubic-bezier(0.0,0.0,0.2,1);
    -webkit-transition: fill .1s cubic-bezier(0.0,0.0,0.2,1),fill-opacity .1s cubic-bezier(0.0,0.0,0.2,1);
    transition: fill .1s cubic-bezier(0.0,0.0,0.2,1),fill-opacity .1s cubic-bezier(0.0,0.0,0.2,1);
    xfill: #f00;
    xfill-opacity: 1;
}

.content .artist_box:hover .yt-button {
    border-bottom: 0px;
    opacity: 1;
}


.artist_box h4.artist_name em {
    font-weight: 300;
    font-size: 0.8em;
    font-style: normal;
}

.artist_box h4.artist_name em::before{
    content: "\a";
    white-space: pre;
}

.artist_box.headliner h4.artist_name {
    font: 300 33px/35px 'Source Sans Pro', sans-serif;
    margin-top: -8px;
}



h2.program_title {
    margin-top: 0.5em;
}




.accordion-toggle {
    display: none;
}

.accordion-title {
    cursor: pointer;
    display: block;
    transition: all 0.1s ease;
    user-select: none;
}

.accordion-content {
    display: none;
    transition: all 0.1s ease;
}

.accordion-toggle:checked + .accordion-title + .accordion-content {
    display: block;
}

.accordion-title:active {
    color: #faf6be;
    color: #f48471;
}

.accordion-title h2::after {
    content: ' +';
    white-space: nowrap;
}
.accordion-toggle:checked + .accordion-title h2::after {
    content: ' -';
}

